

























































































































@import "@/assets/scss/variables.scss";
article.document {
  .actions {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    button {
      background-color: rgba(black, 0.4);
      i {
        color: white;
      }
    }
  }
  aside.photo-details {
    margin-top: 24px;
    h1 {
      font-size: 2rem;
      line-height: 2.2rem;
      @include breakpoint(mobile) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    p {
      font-size: 18px;
      line-height: 28px;
      &.advisory {
        color: $red_warm_3;
      }
    }

    .time-address {
      color: $grey-cold-6;
      font-size: 1rem;
      font-weight: 400;
      margin: 18px 0px;
      .creator {
        display: inline-block;
        font-style: normal;
        text-transform: capitalize;
      }

      time {
        display: inline-block;
        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}
@media screen {
  article {
    background-color: $primary-color;
    overflow: hidden;
    .figure {
      cursor: zoom-out;
      height: 100%;
      width: 100%;
      &.small {
        cursor: zoom-in;
        height: 50%;
      }
    }
    aside.photo-details {
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding: 30px;
      overflow-y: auto;
      overscroll-behavior-y: contain;
      background-color: white;
      padding: 30px;
      @include breakpoint(mobile) {
        padding: 15px;
      }
      transition: transform .3s ease-in-out;
      max-height: 70%;
      max-width: $max-document-width;
    }
  }

  .slide-enter,
  .slide-leave-active {
    transform: translateX(100%);
  }
}

@media print {
  aside.photo-details {
    display: block !important;
  }
}
