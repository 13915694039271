






























































































































































































































































































@import "@/assets/scss/variables.scss";

@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";
@import "bulma/sass/utilities/derived-variables";
@import "bulma/sass/utilities/mixins";
@import "bulma/sass/components/message";

article.document {
  background-color: white;
  color: $dark;
  &::-webkit-scrollbar {
    width: 0.3em;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: darken($background-color, 5);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken($background-color, 15);
  }
  @media screen {
    max-width: 900px;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
    padding: 0px 68px 0px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    @include breakpoint(mobile) {
      padding: 0px 30px;
    }
  }

  h1 {
    font-size: 56px;
    line-height: 53px;
    margin: 24px 0px;

    @include breakpoint(mobile) {
      font-size: 26px;
      line-height: 28px;
    }
  }
  section.meta {
    display: flex;
    align-items: flex-start;

    &.arabic {
      display: flex-reverse;
    }

    .dateline-byline {
      flex: 1;
      margin-right: 15px;
      a {
        text-decoration: underline;
      }
      address {
        display: inline-block;
        font-style: normal;
      }
      > * {
        margin: 4px 5px;
        font-size: 1rem;
      }
      @include breakpoint(mobile) {
        margin-bottom: 15px;
      }
    }

    .update {
      background: $dark;
      color: $light;
      padding: 8px 16px;
      font-weight: 600;
      a {
        color: white;
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 1.8em;
    margin-top: 36px;
    line-height: 1.2em;
  }

  h3 {
    text-transform: capitalize;
    margin-top: 0px;
  }

  .genre {
    margin: 24px 0px 18px 0px;
    a {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 600;
      color: white;
      background-color: $font-color;
      padding: 6px 12px;
      border-radius: 2px;
      text-decoration: none;
    }
  }

  &.canceled {
    h1 {
      text-decoration: line-through;
    }
  }

  .media-gallery {
    // margin-top: 30px;
    margin-left: -30px;
    margin-right: -68px;
    @include breakpoint(mobile) {
      margin-right: -30px;
    }
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }

  .advisory {
    .message-header {
      font-size: 16px;
      font-weight: 600;
      line-height: 1rem;
      background: $dark;
      margin-bottom: 0px;
      a {
        text-decoration: none;
      }
    }
    p {
      margin-top: 0px;
    }
  }

  .time-address {
    color: $grey-cold-6;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
    address {
      display: inline-block;
      font-style: normal;
    }

    time {
      display: inline-block;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  .cols {
    display: flex;
    margin-top: 12px;
      margin-bottom: 2rem !important;
    aside.meta {
      position: sticky;
      top: 5%;
      height: 100%;
      width: 25%;
      margin-top: 25px;
      padding-right: 12px;
      .subtitle{
        font-weight: 600;
        color: $dark;
      }

      @media screen and (max-width: 640px) {
        position: relative !important;
      }
    }
    main {
      margin-top: 25px;
      width: 75%;
      p{
        margin-bottom: 1rem;
      }
      h2{
        margin-bottom: 1rem;
      }
    }
    @include breakpoint(mobile) {
      display: block;
      aside.meta, main {
        width: 100%;
      }
    }
  }

  .actions {
    position: sticky;
    top: 8px;
    float: right;
    transform: translateX(60px);
    z-index: 1;
    @include breakpoint(mobile) {
      display: inline-block;
      position: fixed;
      right: 8px;
      transform: translateX(0px);
      float: none;
      display: block;
    }
    button {
      background-color: rgba(black, 0.4);
      i {
        color: white;
      }
    }
  }

  @media print {
    a[href*='//']:after {
      content:" (" attr(href) ") ";
      color: black;
    }
    p {
      page-break-inside: avoid;
    }
    .actions {
      display: none;
    }
    aside.meta {
      display: none;
    }
  }
  &[lang="ar"] {
    aside.related-articles ul {
      padding-left: inherit;
      padding-right: 0px;
    }
    aside.meta {
      padding-left: 12px;
      padding-right: 0px;
    }
  }
}
