












































































@import "@/assets/scss/variables.scss";
  .related-documents {
    margin: 0 30px;

    @media print {
      display: none;
    }

    select {
      background-color: transparent;
      border: none;
      font-weight: 600;
    }

    h3 {
      position: relative;
      font-size: 30px;
      font-weight: 600;
      line-height: 26px;
      a {
        text-decoration: none;
        color: inherit;
        position: relative;
        z-index: 2;
          span{
            &:after {
            content: "";
            display: block;
            background-color: #7DFAAF;
            position: absolute;
            width: 100%;
            height: 20%;
            bottom: 5px;
            left: 0;
            z-index: -1;
            pointer-events: none;
          }
        }
      }

    }

    .articles {
      display: flex;
      margin-top: 20px;
      @media screen and (max-width: 640px) {
        flex-direction: column;
      }
    }
  }
