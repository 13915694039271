

















































































































































































figure {
  margin: 0px;
  img {
    transform-origin: 0 0;
  }
}
@media print {
  figure {
    img {
      width: 100%;
      height: auto;
      transform: none !important;
    }
  }
}
