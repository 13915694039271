














































































































































@import "@/assets/scss/variables.scss";
article {
  h1 {
    font-size: 50px;
    line-height: 60px;
    @include breakpoint(mobile) {
      font-size: 24px;
      line-height: 24px;
    }
  }
  .time-address {
    color: $grey-cold-6;
    font-size: 1rem;
    font-weight: 400;
    margin: 16px 0px;
    address {
      display: inline-block;
      font-style: normal;
    }

    time {
      display: inline-block;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  p {
    font-size: 18px;
    line-height: 28px;
    &.advisory {
      color: $red_warm_3;
    }
  }
  h2 {
    cursor: pointer;
    text-transform: capitalize;
  }
  h3 {
    text-transform: capitalize;
  }
}
@media screen {
  article.document {
    background-color: white;
    padding: 30px;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    @include breakpoint(mobile) {
      padding: 15px;
    }
    figure {
      margin-left: -30px;
      margin-right: -30px;
      margin-top: -30px;
      background-color: $primary-color;
      @include breakpoint(mobile) {
        margin-left: -15px;
        margin-right: -15px;
        margin-top: -15px;
      }
    }

    main {
      max-width: 800px;
      margin: auto;
    }

    .actions {
      position: fixed;
      top: 8px;
      right: 8px;
      z-index: 1;
      button {
        background-color: rgba(black, 0.4);
        i {
          color: white;
        }
      }
    }
  }
}

@media print {
  figure {
    margin: 0;
  }
}
