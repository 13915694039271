






































































































































































































































@import "@/assets/scss/variables.scss";

@media screen {
  article.document {
    z-index: 999;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    .actions {
      z-index: 1;

      button{
        background: none;
        i{
          color: $dark;
        }
      }
    }
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition: transform .3s ease-in-out;
  }

  .slide-left-enter,
  .slide-right-enter {
    transform: translateX(-100%);
  }
  @media (max-width: $max-document-width) {
    .slide-right-enter {
      transform: translateX(100%);
    }
  }

  .slide-left-leave-to,
  .slide-right-leave-to {
    transform: translateX(-100%);
  }
  @media (max-width: $max-document-width) {
    .slide-right-leave-to {
      transform: translateX(-100%);
    }
  }

  .slide-left-enter-to,
  .slide-left-leave,
  .slide-right-enter-to,
  .slide-right-leave {
    transform: translateX(0%);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to,
  .fade-leave {
    opacity: 1;
  }
}

@media print {
  .document {
    margin: auto;
    .actions {
      display: none;
    }
  }
}
