




































































































































@import "@/assets/scss/variables.scss";
.media-gallery {
  margin-top: 12px;

  figure {
    position: relative;
    & > *:not(.placeholder) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      width: 100%;
    }
    margin: 0;
  }
  nav {
    display: flex;
    justify-content: center;

    @media print {
      display: none;
    }
    ul {
      padding: 0;
      max-width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style-type: none;
      li {
        margin: 0 3px;
        cursor: pointer;
        &.active {
          border-bottom: 4px solid $secondary-color;
          cursor: default;
        }
        img {
          width: 48px;
        }
      }
    }
  }
  p {
    padding: 20px 80px;
    background: #ECEBE9;
    margin-bottom: 10px;
    color: $dark;
    font-size: 14px;

    &.description {
      margin: 0px;
    }
  }
}
