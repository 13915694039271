





























































@import "@/assets/scss/variables.scss";
nav {
  @media print {
    display: none;
  }
  margin-bottom: 2rem;
  &.topics{
    a{
      background: $secondary-color;
    }
  }
  .slug{
    margin-bottom: 15px;
  }
  flex-direction: column;
  a {
    font-size: 16px;
    font-weight: 800;
    text-decoration: none;
    text-transform: capitalize;
    transition: 0.1s ease-out;
    padding: 5px;
    background: #FF6E6E;
    color: $light;
    display: inline;

    &:hover{
      background: $dark;
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: row;
    .slug{
      display: inline-block;
    }
  } 

  @include breakpoint(mobile) {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 15px;

    &.vertical, &.horizontal {
      a {
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 0px;
      }
    }
  }
}
